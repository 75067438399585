import { useCustomerApi } from '@/composables/api/useCustomerApi'
import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { CustomerDto } from '@/api/contracts/models'

export function useCustomersQuery({ enabled } = { enabled: ref(true) }) {
   const client = useCustomerApi()
   return useQuery([QueryKeys.Customers], () => client.list(), {
      enabled,
      staleTime: Infinity
   })
}

export function useCustomersQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useCustomerApi()
   return queryClient.prefetchQuery<CustomerDto[]>([QueryKeys.Customers], () =>
      client.list()
   )
}
